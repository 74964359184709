export async function queryGreenhouseJob(id) {
  let link = 'https://boards-api.greenhouse.io/v1/boards/encompasstechnologiesllp/jobs'
  let url = id? `/${id}?content=true&questions=true`:"?content=true"
  try{
    const response = await fetch(`${link}${url}`); 
    const data = await response.json(); 
    if(data){
      if(id){
        return data
      }
      return data.jobs
    }
    return []
  }catch{
    
  } 
}